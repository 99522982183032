import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
import { RemarkModelComponent } from 'src/app/remark-model/remark-model.component';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { CustomEventsService } from '../../_services/custom-events.service';
import { DatabaseService } from '../../_services/database.service';
import swal from 'sweetalert';
import { StatusModelComponent } from '../status-model/status-model.component';
import * as FileSaver from 'file-saver';  
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';  
const EXCEL_EXTENSION = '.xlsx';  
@Component({
  selector: 'app-ls-dealer-list',
  templateUrl: './list.component.html'
})
export class LeadListComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  
  
  type:any = this.activatedroute.snapshot.params.type
  typeName:any 
  constructor(
    public activatedroute:ActivatedRoute,
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    private render: Renderer2,
    public event:CustomEventsService
    ) {  
      this.db.getStates();
      this.filter.state_name = '';
      this.db.getDistricts(null);
      this.filter.district_name = '';
      
      
      this.event.subscribe('refresh-lead-list', (data: any) => {
        // this.type =this.activatedroute.snapshot.params.type
        // this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
        this.db.getStates();
        this.filter.state_name = '';
        this.db.getDistricts(null);
        this.filter.district_name = '';
        this.filter={};
        this.getCounters();
        console.log('refresh');
        
      })
    }
    ngOnInit() {
      this.getCounters();
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    }
    tabActiveType:any={};
    tabActive:any='All'
    todayDate :any
    pageNo:any = 1;
    tempPageNo = 1;
    totalPages:any
    start:any = 0;
    limit:any = 50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      this.type = this.activatedroute.snapshot.params.type;
      this.typeName =  this.type == 1 ? 'Distributor' : 'Customer';
      this.filter.cust_type_id = this.type;
      this.filter.is_lead = "1";
      this.filter.status = this.tabActive == 'All' ? '' : this.tabActive;
      // this format caused error thats why comment is solution
      // this.filter.date_to = this.filter.date_to ? moment(this.filter.date_to).format('YYYY-DD-YY'): '' ;
      // this.filter.date_from = this.filter.date_from ? moment(this.filter.date_from).format('YYYY-DD-YY'): '' ;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    counters:any=[];
    getCounters(){
      
      this.db.presentLoader();
      this.db.postReq({cust_type_id:this.activatedroute.snapshot.params.type},'dis_network/count').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          // resp['result'].data.map(r=>{
          this.counters= resp['result'].data
          // })
          console.log(this.counters);
          this.tabActive = resp['result'].data[0].status; 
          this.getData();
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    delete(data){
      swal({
        title: "Are you sure?",
        text: "You want to delete this "+this.typeName+"!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.customer_id = data.encrypt_id;
          this.db.postReq(data,'dis_network/delete').subscribe(resp=>{
            if(resp['status'] == 'success'){
              this.getData();
              swal("Deleted", {
                icon: "success",
              });
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    backClicked() {
      this._location.back();
    } 
    status:boolean = false
    
    toggleNav() 
    {
      this.status = !this.status;
      if(this.status) {
        this.render.addClass(document.body, 'toggle-active-tlp');
      }
      else {
        this.render.removeClass(document.body, 'toggle-active-tlp');
      }
    }
    openStatusDialog(data): void {
      console.log(data);
      
      const dialogRef = this.dialog.open(StatusModelComponent, {
        width: '400px',
        autoFocus: false,
        data: {}
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getCounters();     
      });
    }
    loginStatusChange(data){
      swal({
        title: "Are you sure?",
        text: data.login_status == 1  ? "You want to make this Activate Login!" : "You want to make this Inactivate Login!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.login_status = data.login_status == 0 ? 1 : 0;
          data.customer_id = data.encrypt_id;
          this.db.postReq(data,'dis_network/login_status').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal(data.login_status == 0 ? "Inactive!" : "Active", {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          data.login_block = !data.login_block 
          swal("Your data is safe!");
        }
      });
    }
    downloadExcel(){
      this.type = this.activatedroute.snapshot.params.type;
      this.filter.cust_type_id = this.type;
      this.filter.registered = 0;
      this.filter.status = this.tabActive == 'All' ? '' : this.tabActive;
      let reqData = {
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data')
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    file:any
    arrayBuffer:any;
    addfile(event)     
    {    
      this.file= event.target.files[0];     
      let fileReader = new FileReader();    
      fileReader.readAsArrayBuffer(this.file);     
      fileReader.onload = (e) => {    
        this.arrayBuffer = fileReader.result;    
        var data = new Uint8Array(this.arrayBuffer);    
        var arr = new Array();    
        for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
        var bstr = arr.join("");    
        var workbook = XLSX.read(bstr, {type:"binary"});    
        var first_sheet_name = workbook.SheetNames[0];    
        var worksheet = workbook.Sheets[first_sheet_name];    
        var arraylist = XLSX.utils.sheet_to_json(worksheet,{raw:true});     
        console.log(arraylist); 
        this.db.postReq({importData:arraylist,cust_type:this.type},'dis_network/import_network').subscribe(resp=>{
          if(resp['status'] == 'success'){
            swal('Success', {
              icon: "success",
            });
            this.getData();
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })  
      }    
    }
    
  }
  
  
  