import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',

 
})
export class CategoryListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
