import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-colour-add',
  templateUrl: './product-colour-add.component.html',
  // styleUrls: ['./product-colour-add.component.scss']
})
export class ProductColourAddComponent implements OnInit {

  constructor(private _location: Location, public db: DatabaseService, public actRoute: ActivatedRoute) { }

  ngOnInit() {
    if (this.actRoute.snapshot.params.id) {
      this.getDetail(this.actRoute.snapshot.params.id)
    } else {
      this.formsInit({});
    }
  }
  getDetail(id) {
    this.db.presentLoader();

    this.db.postReq({ 'color_id': id }, 'master/color_detail').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        this.formsInit(resp['data']['detail']);

      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errHandler(err);
    })

  }
  form: FormGroup;

  formsInit(data: any) {
    this.form = this.db.formBuilder.group({
      code: [data.code ? data.code : null, [Validators.required]],
      name: [data.name ? data.name : null, [Validators.required]],
      description: [data.description ? data.description : null],
      status: [data.status ? data.status.toString() : "1", [Validators.required]],
      color_id: [data.id ? data.id : null],
    })
  }
  onSubmit() {
    if (this.form.invalid) {
      this.form.controls['name'].markAsTouched();
      this.form.controls['code'].markAsTouched();
      this.form.controls['description'].markAsTouched();
      this.form.controls['status'].markAsTouched();
      return
    }
    console.log(this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value, this.actRoute.snapshot.params.id ? 'master/update_product_color' : 'master/add_product_color').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        this.db.successAlert(resp['status'], resp['message']);
        this.backClicked();
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errDismissLoader();
    })

  }

  backClicked() {
    this._location.back();
  }

}



