import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',

})
export class CategoryDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
