import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import swal from 'sweetalert';
import { DatabaseService } from '../_services/database.service';


import * as Highcharts from 'highcharts';
import highcharts3D from 'highcharts/highcharts-3d.src';
// import drilldown from 'highcharts/modules/drilldown.src.js';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
// let drilldown = require('highcharts/modules/drilldown.src.js');
// highcharts3D(Highcharts);
// drilldown(Highcharts)
// Boost(Highcharts);
// noData(Highcharts);
// More(Highcharts);
// noData(Highcharts);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  
  showLoading:any=false;
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  apiLoaded: Observable<boolean>;
  graphFilters:any={}
  constructor(
    // private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService
    ) { 
      this.graphFilters.drNetworkPieChart = {};
      this.graphFilters.drNetworkPieChart.showFilter = false;
      this.graphFilters.leadSource = {};
      this.graphFilters.leadSource.showFilter = false;
      this.graphFilters.leadStateWiseBarGraph = {};
      this.graphFilters.leadStateWiseBarGraph.showFilter = false;
      this.graphFilters.drStateWiseBarGraph = {};
      this.graphFilters.drStateWiseBarGraph.showFilter = false;
      this.graphFilters.saleUserWorkingState = {};
      this.graphFilters.saleUserWorkingState.showFilter = false;
      this.apiLoaded = this.db.http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBxlrQA-ss1Vt-FtUpoMWhLydAV6G9NCqI', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        );
        console.log(this.apiLoaded,'apiLoaded');
        
      }
      
      ngOnInit() {
        
        this.graphData.drNetworkPieChart = {};
        this.graphData.leadSource = {};
        this.graphData.leadStateWiseBarGraph = {};
        this.graphData.drStateWiseBarGraph = {};
        this.graphData.saleUserWorkingState = {};
        
        this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
        this.getData();;
        // this.sales_working_map();
        // this.graph_lead_source();
        // this.graph_lead_state();
        // this.sales_working_state();
        this.graph_dr_state();
        this.pie_dr_type();
        // this.get_user_lat_lng();
      }
      todayDate :any
      pageNo:any=1;
      tempPageNo =1;
      totalPages:any
      start:any=0;
      limit:any=50;
      totalLength:any;
      filter:any={};
      
      counterData:any;
      graphData:any={};
      getData(){
        
        this.dataNotFound = false;
        this.reqSent = false;
        let reqData = {
          limit : this.limit,
          start : this.start,
          filter : this.filter
        }
        if(this.filter.date_from) this.filter.date_from =  moment(new Date (this.filter.date_from)).format('YYYY-MM-DD')
        if(this.filter.date_to) this.filter.date_to =  moment(new Date (this.filter.date_to)).format('YYYY-MM-DD')
        this.db.presentLoader();
        this.showLoading = true;
        this.db.postReq(reqData,'dashboard/lms').subscribe(resp=>{
          this.reqSent = true;
          
          if(resp['status'] == 'success'){
            this.showLoading = false;
            this.data = resp['result'].data;
            this.counterData =resp['result'].countData
            console.log(this.counterData,'conterdata')
            let leadCountData=[];
            resp['result'].CountData.count.map((r,i)=>{
              leadCountData.push({y:r,name:resp['result'].leadCountData.statusList[i]});
            })
            this.graphData.leadCountData = {
              chart: {
                type: 'pie',
                options3d: {
                  enabled: true,
                  alpha: 45,
                  beta: 0
                }
              },
              title: {
                text: "Lead network by Status"
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  depth: 35,  
                  dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                  }
                }
              },
              series: [{
                name: 'Sales',
                data: leadCountData
              }],
              
            }
            Highcharts.chart('leadCountData-container', this.graphData.leadCountData);
            
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          
        })
      }
      sales_working_map(){
        let reqData = {
          limit : this.limit,
          start : this.start,
          filter : this.filter
        }
        this.db.postReq(reqData,'dashboard/sfa/sales_working_map').subscribe(resp=>{
          this.reqSent = true;
          if(resp['status'] == 'success'){
            // this.data = resp['result'];
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          
        })
      }
      graph_lead_source(){
        if(this.graphFilters.leadSource.date_from) this.graphFilters.leadSource.date_from =  moment(new Date (this.graphFilters.leadSource.date_from)).format('YYYY-MM-DD')
        if(this.graphFilters.leadSource.date_to) this.graphFilters.leadSource.date_to =  moment(new Date (this.graphFilters.leadSource.date_to)).format('YYYY-MM-DD')
        let reqData = {
          limit : this.limit,
          start : this.start,
          filter : this.graphFilters.leadSource
        }
        this.db.postReq(reqData,'/dashboard/sfa/graph_lead_source').subscribe(resp=>{
          this.reqSent = true;
          if(resp['status'] == 'success'){
            this.graphData.leadSource = {
              chart: {
                type: "column",
                options3d: {
                  enabled: true,
                  alpha: 0,
                  beta: 3,
                  depth: 51,
                  viewDistance: 25
                }
              },
              title: {
                text: "Lead Network Sources"
              },
              xAxis: {
                categories: resp['result'].leadSourceBarGraph.sourceList
              },
              yAxis: {
                title: {
                  text: "Number of Leads"
                }
              },
              series: [
                {
                  name: "Lead Network Sources",
                  data: resp['result'].leadSourceBarGraph.count,
                  colorByPoint: true
                }
              ]
            }
            Highcharts.chart('leadSource-container', this.graphData.leadSource);
            
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          
        })
        
      }
      graph_lead_state(){
        if(this.graphFilters.leadStateWiseBarGraph.date_from) this.graphFilters.leadStateWiseBarGraph.date_from =  moment(new Date (this.graphFilters.leadStateWiseBarGraph.date_from)).format('YYYY-MM-DD')
        if(this.graphFilters.leadStateWiseBarGraph.date_to) this.graphFilters.leadStateWiseBarGraph.date_to =  moment(new Date (this.graphFilters.leadStateWiseBarGraph.date_to)).format('YYYY-MM-DD')
        let reqData = {
          limit : this.limit,
          start : this.start,
          filter : this.graphFilters.leadStateWiseBarGraph
        }
        this.db.postReq(reqData,'dashboard/sfa/graph_lead_state').subscribe(resp=>{
          this.reqSent = true;
          if(resp['status'] == 'success'){
            this.graphData.leadStateWise = {
              chart: {
                type: "column",
                options3d: {
                  enabled: true,
                  alpha: 0,
                  beta: 3,
                  depth: 51,
                  viewDistance: 25
                }
              },
              title: {
                text: "Lead Network State wise"
              },
              xAxis: {
                categories: resp['result'].leadStateWiseBarGraph.stateList
              },
              yAxis: {
                title: {
                  text: "Number of Leads"
                }
              },
              series: [
                {
                  name: "Lead Network State wise",
                  data: resp['result'].leadStateWiseBarGraph.count,
                  colorByPoint: true
                }
              ]
            }
            Highcharts.chart('leadStateWise-container', this.graphData.leadStateWise);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          
        })
      }
      // sales_working_state(){
      //   if(this.graphFilters.saleUserWorkingState.date_from) this.graphFilters.saleUserWorkingState.date_from =  moment(new Date (this.graphFilters.saleUserWorkingState.date_from)).format('YYYY-MM-DD')
      //   if(this.graphFilters.saleUserWorkingState.date_to) this.graphFilters.saleUserWorkingState.date_to =  moment(new Date (this.graphFilters.saleUserWorkingState.date_to)).format('YYYY-MM-DD')
      //   let reqData = {
      //     limit : this.limit,
      //     start : this.start,
      //     filter : this.graphFilters.saleUserWorkingState
      //   }
      //   this.db.postReq(reqData,'dashboard/sfa/sales_working_state').subscribe(resp=>{
      //     this.reqSent = true;
      //     if(resp['status'] == 'success'){
      //       this.graphData.saleUserWorkingState ={
      //         chart: {
      //           type: 'column',
      //           options3d: {
      //             enabled: true,
      //             alpha: 45,
      //             beta: 0
      //           }
      //         },
      //         title: {
      //           text: 'Sales by Region'
      //         },
      //         plotOptions: {
      //           pie: {
      //             allowPointSelect: true,
      //             cursor: 'pointer',
      //             depth: 35,
      //             dataLabels: {
      //               enabled: true,
      //               format: '{point.name}'
      //             }
      //           }
      //         },
      //         series: [{
      //           name: 'Sales User',
      //           "data": [
      //             {
      //               "name": "Andaman & Nicobar Islands",
      //               "y": 2,
      //               "drilldown": "andaman-&-nicobar-islands"
      //             },
      //             {
      //               "name": "Andhra Pradesh",
      //               "y": 4,
      //               "drilldown": "andhra-pradesh"
      //             },
      //             {
      //               "name": "Arunachal Pradesh",
      //               "y": 1,
      //               "drilldown": "arunachal-pradesh"
      //             },
      //             {
      //               "name": "Assam",
      //               "y": 2,
      //               "drilldown": "assam"
      //             },
      //             {
      //               "name": "Bihar",
      //               "y": 1,
      //               "drilldown": "bihar"
      //             },
      //             {
      //               "name": "Chandigarh",
      //               "y": 1,
      //               "drilldown": "chandigarh"
      //             },
      //             {
      //               "name": "Chattisgarh",
      //               "y": 1,
      //               "drilldown": "chattisgarh"
      //             },
      //             {
      //               "name": "Daman & Diu",
      //               "y": 1,
      //               "drilldown": "daman-&-diu"
      //             },
      //             {
      //               "name": "Gujarat",
      //               "y": 1,
      //               "drilldown": "gujarat"
      //             },
      //             {
      //               "name": "Haryana",
      //               "y": 1,
      //               "drilldown": "haryana"
      //             }
      //           ],
      //         }],
      //         drilldown: {
      //           series:[
      //             {
      //               "name": "Andaman & Nicobar Islands",
      //               "id": "andaman-&-nicobar-islands",
      //               "data": [
      //                 [
      //                   "test",
      //                   ""
      //                 ],
      //                 [
      //                   "vinay",
      //                   "23"
      //                 ],
      //                 [
      //                   "test2",
      //                   "q1q1"
      //                 ]
      //               ]
      //             },
      //             {
      //               "name": "Andhra Pradesh",
      //               "id": "andhra-pradesh",
      //               "data": [
      //                 [
      //                   "Head User",
      //                   "trer"
      //                 ],
      //                 [
      //                   "Deepanshu",
      //                   "Sales-code-1"
      //                 ],
      //                 [
      //                   "test",
      //                   null
      //                 ],
      //                 [
      //                   "vinay",
      //                   "23"
      //                 ]
      //               ]
      //             },
      //             {
      //               "name": "Arunachal Pradesh",
      //               "id": "arunachal-pradesh",
      //               "data": [
      //                 [
      //                   "test",
      //                   null
      //                 ],
      //                 [
      //                   "test",
      //                   "123123"
      //                 ]
      //               ]
      //             },
      //             {
      //               "name": "Assam",
      //               "id": "assam",
      //               "data": [
      //                 [
      //                   "atul",
      //                   "1234"
      //                 ],
      //                 [
      //                   "Deepanshu",
      //                   "Sales-code-1"
      //                 ]
      //               ]
      //             },
      //             {
      //               "name": "Bihar",
      //               "id": "bihar",
      //               "data": [
      //                 [
      //                   "Rahul kumar",
      //                   "123456"
      //                 ],
      //                 [
      //                   "Vinay Kumar",
      //                   "Sales-code-2"
      //                 ],
      //                 [
      //                   "Rahul Bhutani",
      //                   "Rahul-App"
      //                 ]
      //               ]
      //             },
      //             {
      //               "name": "Chandigarh",
      //               "id": "chandigarh",
      //               "data": [
      //                 [
      //                   "Vinay Kumar",
      //                   "Sales-code-2"
      //                 ],
      //                 [
      //                   "Rahul Bhutani",
      //                   "Rahul-App"
      //                 ]
      //               ]
      //             },
      //             {
      //               "name": "Chattisgarh",
      //               "id": "chattisgarh",
      //               "data": [
      //                 [
      //                   "Vinay Kumar",
      //                   "Sales-code-2"
      //                 ],
      //                 [
      //                   "Rahul Bhutani",
      //                   "Rahul-App"
      //                 ]
      //               ]
      //             },
      //             {
      //               "name": "Daman & Diu",
      //               "id": "daman-&-diu",
      //               "data": [
      //                 [
      //                   "Vinay Kumar",
      //                   "Sales-code-2"
      //                 ],
      //                 [
      //                   "Rahul Bhutani",
      //                   "Rahul-App"
      //                 ]
      //               ]
      //             },
      //             {
      //               "name": "Gujarat",
      //               "id": "gujarat",
      //               "data": [
      //                 [
      //                   "Vinay Kumar",
      //                   "Sales-code-2"
      //                 ],
      //                 [
      //                   "Rahul Bhutani",
      //                   "Rahul-App"
      //                 ]
      //               ]
      //             },
      //             {
      //               "name": "Haryana",
      //               "id": "haryana",
      //               "data": [
      //                 [
      //                   "Rahul Rathore",
      //                   "12345"
      //                 ],
      //                 [
      //                   "Vinay Kumar",
      //                   "Sales-code-2"
      //                 ],
      //                 [
      //                   "Rahul Bhutani",
      //                   "Rahul-App"
      //                 ]
      //               ]
      //             }
      //           ]
      //         }
      //       }
      //       Highcharts.chart('saleUserWorkingState-container', this.graphData.saleUserWorkingState);
      //     }else{
      //       if(resp['message']=='Invalid token'){
      //         this.db.sessionExpire();
      //         return;
      //       }
      //       this.db.presentAlert(resp['status'],resp['message'])
      //     }
      //   },err=>{
      
      //   })
      // }
      
      graph_dr_state(){
        if(this.graphFilters.drStateWiseBarGraph.date_from) this.graphFilters.drStateWiseBarGraph.date_from =  moment(new Date (this.graphFilters.drStateWiseBarGraph.date_from)).format('YYYY-MM-DD')
        if(this.graphFilters.drStateWiseBarGraph.date_to) this.graphFilters.drStateWiseBarGraph.date_to =  moment(new Date (this.graphFilters.drStateWiseBarGraph.date_to)).format('YYYY-MM-DD')
        let reqData = {
          limit : this.limit,
          start : this.start,
          filter : this.graphFilters.drStateWiseBarGraph
        }
        this.db.postReq(reqData,'dashboard/sfa/graph_dr_state').subscribe(resp=>{
          this.reqSent = true;
          if(resp['status'] == 'success'){
            
            resp['result'].drStateWiseBarGraph.map(r=>{
              r.y = r.count
              r.name = r.state_name
            })
            this.graphData.drStateWise = {
              chart: {
                type: 'pie',
                options3d: {
                  enabled: true,
                  alpha: 45,
                  beta: 0
                }
              },
              title: {
                text: "Distribution network by state"
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  depth: 35,  
                  dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                  }
                }
              },
              series: [{
                name: 'Sales',
                data: resp['result'].drStateWiseBarGraph
              }],
              
            }
            Highcharts.chart('drStateWise-container', this.graphData.drStateWise);
            
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          
        })
        
      }
      
      pie_dr_type(){
        if(this.graphFilters.drNetworkPieChart.date_from) this.graphFilters.drNetworkPieChart.date_from =  moment(new Date (this.graphFilters.drNetworkPieChart.date_from)).format('YYYY-MM-DD')
        if(this.graphFilters.drNetworkPieChart.date_to) this.graphFilters.drNetworkPieChart.date_to =  moment(new Date (this.graphFilters.drNetworkPieChart.date_to)).format('YYYY-MM-DD')
        let reqData = {
          limit : this.limit,
          start : this.start,
          filter : this.graphFilters.drNetworkPieChart
        }
        this.db.postReq(reqData,'dashboard/sfa/pie_dr_type').subscribe(resp=>{
          this.reqSent = true;
          if(resp['status'] == 'success'){
            resp['result'].drNetworkPieChart.map(r=>{
              r.y = r.count
              r.name = r.cust_type
            })
            this.graphData.drNetwork = {
              chart: {
                type: 'pie',
                options3d: {
                  enabled: true,
                  alpha: 45,
                  beta: 0
                }
              },
              title: {
                text: "Distribution network by Type"
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  depth: 35,  
                  dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                  }
                }
              },
              series: [{
                name: 'Sales',
                data: resp['result'].drNetworkPieChart
              }],
              
            }
            Highcharts.chart('drNetwork-container', this.graphData.drNetwork);
            
            
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          
        })
      }
      
      mapData:any=[];
      get_user_lat_lng(){
        let reqData = {
          limit : this.limit,
          start : this.start,
          filter : this.graphFilters.drStateWiseBarGraph
        }
        this.db.postReq(reqData,'dashboard/sfa/sales_working_map').subscribe(resp=>{
          this.reqSent = true;
          if(resp['status'] == 'success'){
            this.mapData = resp['result'].salesUserMapLocation;
            this.mapData.map(r=>{
              if(r.last_lat)r.last_lat = Number(r.last_lat);
              if(r.last_lng)r.last_lng = Number(r.last_lng);
              r.lable = r.name+'-('+r.emp_code+') '+(r.last_address  ? r.last_address : '');
            })
            console.log(this.mapData);
            
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          
        })
        
      }
      backClicked() {
        // this._location.back();
      }
      goToUserDetail(data){
        console.log(data);
        this.db.router.navigateByUrl('sales-user-detail/'+data.user_encrypt_id)
      }
      goToDrDetail(data){
        if(data.is_lead == '1')this.db.router.navigateByUrl('dr-detail/'+data.customer_encrypt_id)
        else this.db.router.navigateByUrl('dealer-basic/'+data.customer_encrypt_id)
      }
      previous:any;
      clickedMarker(infowindow) {
        console.log('clickedMarker');
        
        if (this.previous) {
            this.previous.close();
        }
        this.previous = infowindow;
     }
    }
    