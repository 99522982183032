import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-postal-add',
  templateUrl: './postal-add.component.html'
})
export class PostalAddComponent implements OnInit {
  
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    this.db.getStates()
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'postal_id':id},'master/postal_master_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['result']['data']);
        this.db.getDistricts(this.form.value.state_name)
        this.db.getCity(this.form.value.state_name,this.form.value.district_name)
      }else{
if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      state_name: [data.state_name ? data.state_name : null ,[Validators.required]],
      district_name: [data.district_name ? data.district_name : null ,[Validators.required]],
      city: [data.city ? data.city : null ,[Validators.required]],
      area: [data.area ? data.area : null ,[Validators.required]],
      zone: [data.zone ? data.zone : null ,[Validators.required]],
      beat: [data.beat ? data.beat : null ,[Validators.required]],
      pincode: [data.pincode ? data.pincode : null , [Validators.required]],
      status: [data.status ? data.status.toString() :"1" , [Validators.required]],
      postal_id: [data.id ? data.id : null],
    })
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.controls['state_name'].markAsTouched();
      this.form.controls['district_name'].markAsTouched();
      this.form.controls['area'].markAsTouched();
      this.form.controls['city'].markAsTouched();
      this.form.controls['zone'].markAsTouched();
      this.form.controls['beat'].markAsTouched();
      this.form.controls['pincode'].markAsTouched();
      this.form.controls['status'].markAsTouched();
      return
    }
    console.log(this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.actRoute.snapshot.params.id ? 'master/postal_master_update' : 'master/postal_master_add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  
}
