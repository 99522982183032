import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';

import {Location} from '@angular/common';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import * as XLSX from 'xlsx';
import swal from 'sweetalert';

@Component({
  selector: 'app-coupon-code-import-model',
     templateUrl: './coupon-code-import-model.component.html',
})
export class CouponCodeImportModelComponent implements OnInit {

  constructor(public db:DatabaseService,@Inject(MAT_DIALOG_DATA) public data: any,private _location: Location) { }

  ngOnInit() {
    this.formsInit(this.data);
    this.getOfferData();
    
  }

  offerHandler() {
    this.offerData.map(r => {
      if (r.id == this.form.value.offer_id) {
        console.log(r);
        this.form.controls['valid_upto'].setValue(moment(r.end_date).format('YYYY-MM-DD'));
        this.form.controls['offer_title'].setValue(r.title);
        // this.form.controls['offer_code'].setValue(r.code);
      }
    })
    console.log(this.form.value);

  }

  offerData: any = [];
  todayDate: any
  filteredOfferData: any = [];
  getOfferData() {

    let reqData = {
      limit: 100000,
      start: 0,
      filter: {
        status: 1
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'offer/list').subscribe(resp => {

      if (resp['status'] == 'success') {
        this.offerData = resp['result'].data;
        this.filteredOfferData = resp['result'].data;
        console.log(this.offerData,'offerdata');
        console.log(this.filteredOfferData,'filterdat');
        
        
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errHandler(err);
    })
  }
  couponlist:any
  file:any
  arrayBuffer:any;
  fileName:any;
  addfile(event)     
  {    
    this.file= event.target.files[0];     
    let fileReader = new FileReader();  
    this.fileName = this.file.name;  
    fileReader.readAsArrayBuffer(this.file);     
    fileReader.onload = (e) => {    
      this.arrayBuffer = fileReader.result;    
      var data = new Uint8Array(this.arrayBuffer);    
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      var bstr = arr.join("");    
      var workbook = XLSX.read(bstr, {type:"binary"});    
      var first_sheet_name = workbook.SheetNames[0];    
      var worksheet = workbook.Sheets[first_sheet_name];    
      var arraylist = XLSX.utils.sheet_to_json(worksheet,{raw:true});
      console.log("arraylist",arraylist)

      var newarraylist = arraylist.map((item)=>({
        coupon_code:item["Coupon Code"],
        product_code:item["Product Code"],
        coupon_value:item["Coupon Value"]
        
      }))
      console.log("newarraylist",newarraylist)
      this.couponlist = newarraylist
      console.log("couponlist",this.couponlist)
      console.log("file name",this.fileName)

      // this.couponlist = arraylist 
      // console.log('coupondata',this.couponlist); 
    }    
  }

  form: FormGroup;
  formsInit(data:any) {
    console.log(this.data);
    
    this.form = this.db.formBuilder.group({
      offer_title: [data.offer_title ? data.offer_title : null ,[]],
      valid_upto: [data.valid_upto ? data.valid_upto : null ,[Validators.required]],
      offer_id:[data.valid_upto ? data.valid_upto : null ,[Validators.required]],
      
    })
    // console.log(this.form.value);

  }
 
  onSubmit(){
    // console.log('formvalue',this.form.value)
    if(this.form.invalid && this.couponlist.length == 0){
      return
    }
    
    this.db.postReq({offer:this.form.value,importData:this.couponlist},'coupon_code/import_coupon_data').subscribe(resp=>{
      if(resp['status'] == 'success'){
        swal('Success', {
          icon: "success",
        });
        this.db.matdialog.closeAll();
        // this.getData();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })  
   
    
  }
  backClicked() {
    this._location.back();
  }  
}

